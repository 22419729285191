import { Component, Input, OnInit } from '@angular/core';
import { KeyTextField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-pros-and-cons-slice',
    templateUrl: './pros-and-cons-slice.component.html',
    styleUrls: ['./pros-and-cons-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class ProsAndConsSliceComponent implements OnInit {
    @Input() body!: ProsAndConsSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => '<h2 class="text-size-ml mb-5">' + children + '</h2>',
        list: ({ children }) => '<ul class="list-unstyled">' + children + '</ul>',
        listItem: ({ children }) => '<li class="mt-2 list-item-point">' + children + '</li>'
    };
}

export type ProsAndConsSlice = Slice<
    'pros-and-cons',
    {
        title: TitleField;
        pros_title: KeyTextField;
        pros_description: RichTextField;
        cons_title: KeyTextField;
        cons_description: RichTextField;
        anker: KeyTextField;
    },
    never
>;

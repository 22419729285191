<div class="container" [class]="body.primary.padding_top ? 'pt-5 pt-lg-6' : ''" appAos>
    <div class="row">
        <div class="col">
            <div [innerHTML]="body.primary.text | renderHtml : htmlSerializer"></div>
        </div>
        @if (body.primary.show_shape) {
        <div class="col-2 d-flex align-items-center justify-content-end d-none d-lg-block">
            <img src="./assets/images/dots-plus-vertical.svg" alt="Design-Element" class="shape" />
        </div>
        }
    </div>
</div>

<div class="container py-4 py-md-6" appAos>
    <div class="row">
        <div class="col-12">
            <div [innerHTML]="body.primary.title | renderHtml : htmlSerializer"></div>
        </div>
        <div class="col-lg-6">
            <div class="background-pink p-3 mb-5">
                <h3 class="mb-0 color-white text-size-s">{{ body.primary.pros_title }}</h3>
            </div>
            <div [innerHTML]="body.primary.pros_description | renderHtml : htmlSerializer"></div>
        </div>
        <div class="col-lg-6">
            <div class="background-pink p-3 mb-5">
                <h3 class="mb-0 color-white text-size-s">{{ body.primary.cons_title }}</h3>
            </div>
            <div [innerHTML]="body.primary.cons_description | renderHtml : htmlSerializer"></div>
        </div>
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { BooleanField, RichTextField, Slice } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { serializeHyperlink } from '../../../utils/prismic';
import { AosDirective } from '../../aos/aos.directive';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-text-slice',
    templateUrl: './text-slice.component.html',
    styleUrls: ['./text-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, RenderHtmlPipe]
})
export class TextSliceComponent implements OnInit {
    @Input() body!: TextSlice;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading1: ({ children }) => '<h1 class="text-size-l">' + children + '</h1>',
        heading2: ({ children }) => '<h2 class="text-size-m">' + children + '</h2>',
        heading3: ({ children }) => '<h3 class="text-size-s">' + children + '</h3>',
        paragraph: ({ children }) => `<p class=${this.body.primary.small_text_size ? 'text-size-xs' : ''}>` + children + '</p>',
        hyperlink: ({ children, node }) => serializeHyperlink(children, node)
    };
}

export type TextSlice = Slice<
    'text',
    {
        text: RichTextField;
        show_shape: BooleanField;
        padding_top: BooleanField;
        small_text_size: BooleanField;
    },
    never
>;
